import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import excel from 'vue-excel-export'
import VueAxios from 'vue-axios'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueAxios, axios)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(excel)
// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
const Url = 'https://www.nuc.edu.iq/Al-Nisour/public/api/';
export const filesUrl = "https://www.nuc.edu.iq/Al-Nisour/public/";
// const Url = 'http://127.0.0.1:8000/api/'
// export const filesUrl = 'http://127.0.0.1:8000'
export const SID = 'service_3ksnmeh'
export const TID = 'template_bwrxehi'
export const AID = 'NXKa570Z16A-MOa_X'
axios.interceptors.request.use(
    (config) => {
        config.baseURL = Url
        config.headers['Authorization'] = `Bearer ${localStorage.getItem(
      'NisourToken'
    )}`
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
