

const auth = {
    // namespaced: true,
    state: {
        token:null,
        user_id:null,
        teacher_id:null,
        full_name:null,
        email:null,
        teacher_id:null,
        image:null,
        role:null
    },
    getters: {
        gUID :state=>{
            return state.user_id
        },
        gR :state=>{
            return state.role
        },
        getToken(state){
            return state.token
        },
        gTID(state){
            // if(state.teacher_id){
                return state.teacher_id
            // }else{
            //     actions.gUI()
            // }
        },
        gFullName(state){
            return state.full_name
        },
        gImage(state){
            return state.image
        },
        gRole(state){
            return state.role
        }
    },
    mutations: {
        storeToken(state,token){
            state.token = token
            localStorage.setItem("NisourToken", token);
        },
        storeInfo(state,i){
            state.user_id = i.data.user_id;
            state.teacher_id = i.data.teacher_id;
            state.role = i.data.rule.name;
            localStorage.setItem("NisourUserId", i.data.user_id)
            localStorage.setItem("NisourTeacherId", i.data.teacher_id)
            localStorage.setItem("NisourRole", i.data.rule.name)
            localStorage.setItem("NisourUserName", i.data.full_name)
            localStorage.setItem("NisourEmail", i.data.email)
            localStorage.setItem("NisourDepartmentId", i.data.department_id)
        }
    },
    actions: {
        gUI(context){
            this.axios.get("users/me").then((res) => {
                context.commit("storeInfo", res);
                }).catch(e=>{
                  console.log("error user info")
                  console.log(e)
                })
        }
    },
}
export default auth;