
export default [
  {
    path: '/auth/user-list',
    name: 'users-list',
    component: () => import('@/views/admin/users.vue'),

  },
  {
    path: '/auth/employees',
    name: 'employees-list',
    component: () => import('@/views/admin/employees.vue'),

  },
  {
    path: '/auth/employees/receivables',
    name: 'employees-storage',
    component: () => import('@/views/admin/employeesFrrStg.vue'),

  },
  {
    path: '/auth/employees/receivables/:id',
    name: 'employees-storage-id',
    component: () => import('@/views/admin/employeeFrrStg.vue'),

  },
  {
    path: '/auth/employees/:id/receivables',
    name: 'employees-index-storage',
    component: () => import('@/views/admin/outstorage.vue'),

  },
  {
    path: '/auth/addition/:id',
    name: 'addition',
    component: () => import('@/views/admin/addition.vue'),

  },
  {
    path: '/auth/employees/documents',
    name: 'employee-documents-list',
    component: () => import('@/views/admin/EDocuments.vue'),
  },
  {
    path: '/auth/services',
    name: 's-list',
    component: () => import('@/views/admin/es.vue'),
  },
  {
    path: '/auth/employees/documents/:id',
    name: 'employee-documents-list-notif',
    component: () => import('@/views/admin/EDocuments.vue'),
  },
  {
    path: '/auth/employees/documents/show/:id',
    name: 'employee-documents-list-show',
    component: () => import('@/views/admin/SEDocuments.vue'),
  },
  {
    path: '/auth/user-list/:id',
    name: 'users-list-notif',
    component: () => import('@/views/admin/users.vue'),

  },
  {
    path: '/auth/luctures',
    name: 'lectures-list',
    component: () => import('@/views/admin/lectures.vue'),

  },
  {
    path: '/auth/luctures/:id',
    name: 'lectures-notif',
    component: () => import('@/views/admin/lectures.vue'),

  },
  {
    path: '/auth/lucture/:id/files',
    name: 'lecture-files',
    component: () => import('@/views/admin/files-lecture.vue'),

  },
  {
    path: '/auth/lucture/:id/files',
    name: 'lecture-files-notif',
    component: () => import('@/views/admin/files-lecture.vue'),

  },
  {
    path: '/auth/departments',
    name: 'departments',
    component: () => import('@/views/admin/department.vue'),

  },
  {
    path: '/auth/types',
    name: 'types',
    component: () => import('@/views/admin/types.vue'),

  },
  {
    path: '/auth/subtypes',
    name: 'subtypes',
    component: () => import('@/views/admin/subtypes.vue'),

  },
  {
    path: '/auth/About',
    name: 'about',
    component: () => import('@/views/admin/about.vue'),

  },
  {
    path: '/auth/protection',
    name: 'protection',
    component: () => import('@/views/admin/protection.vue'),
  },
  {
    path: '/auth/stage',
    name: 'stage',
    component: () => import('@/views/admin/stage.vue'),

  },
  {
    path: '/auth/gradguated',
    name: 'gradguated',
    component: () => import('@/views/admin/gradguated.vue'),

  },
  {
    path: '/auth/student-researches',
    name: 'student_researches',
    component: () => import('@/views/admin/student_researches.vue'),

  },
  {
    path: '/auth/news',
    name: 'news',
    component: () => import('@/views/admin/news.vue'),

  }, 
  {
    path: '/auth/news/:id',
    name: 'news-notif',
    component: () => import('@/views/admin/news.vue'),

  },
  {
    path: '/auth/agreements',
    name: 'agreements',
    component: () => import('@/views/admin/agreement.vue'),

  },
  {
    path: '/auth/jobs',
    name: 'jobs',
    component: () => import('@/views/admin/jobs.vue'),

  },
  {
    path: '/auth/applies',
    name: 'applies',
    component: () => import('@/views/admin/applies.vue'),
  },
  {
    path: '/auth/ranking',
    name: 'ranking',
    component: () => import('@/views/admin/ranking.vue'),
  },
  {
    path: '/auth/course',
    name: 'course',
    component: () => import('@/views/admin/course.vue'),
  },
  {
    path: '/auth/faq',
    name: 'faq',
    component: () => import('@/views/admin/faq.vue'),
  },
  {
    path: '/auth/fees',
    name: 'fees',
    component: () => import('@/views/admin/fees.vue'),
  },
  {
    path: '/auth/documents',
    name: 'documents',
    component: () => import('@/views/admin/documents.vue'),
  },
  {
    path: '/auth/documents/second',
    name: 'documentssecond',
    component: () => import('@/views/admin/documentsfull.vue'),
  },
  {
    path: '/auth/documents/:id',
    name: 'documents-notif',
    component: () => import('@/views/admin/documents.vue'),

  },
  {
    path: '/auth/pages',
    name: 'pages',
    component: () => import('@/views/admin/pages.vue'),
  },
  {
    path: '/auth/roles',
    name: 'roles',
    component: () => import('@/views/admin/roles.vue'),
  },
  {
    path: '/auth/tables',
    name: 'tables',
    component: () => import('@/views/admin/table.vue'),
  },
  {
    path: '/auth/groups',
    name: 'groups',
    component: () => import('@/views/admin/groups.vue'),
  },
  {
    path: '/auth/storage/types',
    name: 'meterialtypes',
    component: () => import('@/views/admin/meterialtypes.vue'),
  },
  {
    path: '/auth/storage/category',
    name: 'materialcategory',
    component: () => import('@/views/admin/materialcategory.vue'),
  },
  {
    path: '/auth/storage',
    name: 'instorage',
    component: () => import('@/views/admin/instorage.vue'),
  },
  {
    path: '/auth/receivables',
    name: 'receivables',
    component: () => import('@/views/admin/outstorage.vue'),
  },
  {
    path: '/profile/info',
    name: 'profile-info',
    component: () => import('@/views/admin/info.vue'),
  },
  {
    path: '/profile/info/:id',
    name: 'profile-info-notif',
    component: () => import('@/views/admin/info.vue'),

  },
  {
    path: '/lecturer/researches',
    name: 'researches',
    component: () => import('@/views/admin/researches.vue'),
  },
  {
    path: '/lecturer/researches/:id',
    name: 'researches-notif',
    component: () => import('@/views/admin/researches.vue'),
  },
  {
    path: '/subcategories',
    name: 'subcategory',
    component: () => import('@/views/admin/subcategory.vue'),
  },
  {
    path: '/subcategories/:id',
    name: 'subcategory-notif',
    component: () => import('@/views/admin/subcategory.vue'),
  },
  {
    path: '/library',
    name: 'library',
    component: () => import('@/views/admin/library.vue'),
  },
  {
    path: '/library/:id',
    name: 'library-notif',
    component: () => import('@/views/admin/library.vue'),
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/views/admin/category.vue'),
  },
  {
    path: '/categories/:id',
    name: 'categories-notif',
    component: () => import('@/views/admin/category.vue'),
  },
  {
    path: '/auth/welcome',
    name: 'welcome',
    component: () => import('@/views/admin/welcome.vue'),
    meta: {
      layout: 'full',
    },
  },
  //new pages
  {
    path: '/auth/services',
    name: 's-list',
    component: () => import('@/views/admin/es.vue'),
  },
  {
    path: '/auth/emails',
    name: 'em-list',
    component: () => import('@/views/admin/em.vue'),
  },
  {
    path: '/auth/emails/:id',
    name: 'em-notif',
    component: () => import('@/views/admin/em.vue'),
  },
  {
    path: '/auth/endorsement',
    name: 'end',
    component: () => import('@/views/admin/end.vue'),
  },
  {
    path: '/auth/endorsement/:id',
    name: 'end-notif',
    component: () => import('@/views/admin/end.vue'),
  },
  {
    path: '/auth/card',
    name: 'ca',
    component: () => import('@/views/admin/ca.vue'),
  },
  {
    path: '/auth/card/:id',
    name: 'ca-notif',
    component: () => import('@/views/admin/ca.vue'),
  },
  {
    path: '/auth/poseponeexam',
    name: 'po',
    component: () => import('@/views/admin/po.vue'),
  },
  {
    path: '/auth/poseponeexam/:id',
    name: 'po-notif',
    component: () => import('@/views/admin/po.vue'),
  },
  {
    path: '/auth/scholarship',
    name: 'sc',
    component: () => import('@/views/admin/sc.vue'),
  },
  {
    path: '/auth/scholarship:id',
    name: 'sc-notif',
    component: () => import('@/views/admin/sc.vue'),
  },
  {
    path: '/auth/journals',
    name: 'jS',
    component: () => import('@/views/admin/jS.vue'),
  },
  {
    path: '/auth/issues',
    name: 'iS',
    component: () => import('@/views/admin/aS.vue'),
  },
  {
    path: '/auth/articles',
    name: 'arS',
    component: () => import('@/views/admin/arS.vue'),
  },
  {
    path: '/auth/jourals/contacts',
    name: 'jC',
    component: () => import('@/views/admin/jC.vue'),
  },
  {
    path: '/auth/std/status',
    name: 'sI',
    component: () => import('@/views/admin/sI.vue'),
  },
  {
    path: '/auth/std/info',
    name: 'sIA',
    component: () => import('@/views/admin/sIA.vue'),
  },
  {
    path: '/auth/students/status',
    name: 'sIS',
    component: () => import('@/views/admin/stdsts.vue'),
  },
  {
    path: '/auth/directs',
    name: 'dir',
    component: () => import('@/views/admin/dir.vue'),
  },
  {
    path: '/auth/directs/:id',
    name: 'dir-notif',
    component: () => import('@/views/admin/dir.vue'),
  },
  {
    path: '/auth/presences',
    name: 'pre',
    component: () => import('@/views/admin/pre.vue'),
  },
  {
    path: '/auth/extrapre',
    name: 'extrapre',
    component: () => import('@/views/admin/extrapre.vue'),
  },
  {
    path: '/auth/presences/check/:id',
    name: 'presence-check-list-show',
    component: () => import('@/views/admin/prech.vue'),
  },
  {
    path: '/exams',
    name: 'exams',
    component: () => import('@/views/admin/exams.vue'),
  },
]